<template>
  <div class="page-container dashboard-hospitalization-page">
    <portal to="page-name">Dashboard</portal>
    <div class="dashboard-tiles">
      <div class="tile-item c-hand">
        <div class="tile-icon text-secondary">
          <fa-icon :icon="['fal', 'user']"></fa-icon>
        </div>
        <div class="tile-label">Média de pacientes/dia</div>
        <div class="tile-value">0</div>
      </div>
      <div class="tile-item c-hand">
        <div class="tile-icon text-secondary">
          <fa-icon :icon="['fal', 'user-clock']"></fa-icon>
        </div>
        <div class="tile-label">Taxa de permanência/dia</div>
        <div class="tile-value">0</div>
      </div>
      <div class="tile-item c-hand">
        <div class="tile-icon text-secondary">
          <fa-icon :icon="['fal', 'procedures']"></fa-icon>
        </div>
        <div class="tile-label">Taxa de ocupação (por leito/dia)</div>
        <div class="tile-value">0</div>
      </div>
    </div>
    <div class="card chart-card">
      <div class="card-body">
        <div class="card-title">Quantidade de internações por mês</div>
        <st-line-chart
          :chart-data="charts.hospitalization.data"
          :options="charts.hospitalization.options"
          :height="245"/>
      </div>
    </div>
    <div class="card patient-card">
      <div class="card-body">
        <div class="card-title">Pacientes com plano terapêutico pendente</div>
        <div class="scroll-list-wrapper">
          <div class="scroll-list">
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Nome do paciente</th>
                <th>Horários</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in stats.patients" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                  <span v-for="(hour, n) in item.hours" :key="n">
                    {{ hour }} <span v-if="n + 1 < item.hours.length">|</span>
                  </span>
                </td>
                <td class="text-right">
                  <button class="btn btn-action btn-icon btn-gray btn-sm">
                    <fa-icon :icon="['fal', 'search']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      stats: {
        patients: [],
      },
      charts: {
        hospitalization: {
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
              xAxes: [{
                ticks: {
                  callback: value => value,
                },
                stacked: true,
              }],
            },
          },
        },
      },
      prescriptions: [],
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  @import "./src/assets/scss/mixins";

  .dashboard-hospitalization-page {
    .dashboard-tiles {
      display: flex;
      padding: 0 0 $layout-spacing-lg 0;
      color: $gray-color-dark;
      .tile-item {
        background-color: $light-color;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        flex: 1;
        margin-left: $layout-spacing-lg;
        padding: 20px;
        position: relative;
        text-align: center;
        &:first-child {
          margin-left: 0;
        }
      }
      .tile-icon {
        margin-bottom: $layout-spacing;
        svg {
          display: block;
          height: 1.2rem;
          margin: 0 auto;
          width: auto;
        }
      }
      .tile-label {
        font-size: $font-size-sm;
        line-height: $font-size-sm;
        margin: $layout-spacing-sm 0 calc($layout-spacing-sm / 2) 0;
      }
      .tile-value {
        color: $gray-color-dark;
        font-size: $font-size-lg;
        font-weight: 600;
        line-height: $font-size;
        margin-top: $layout-spacing;
      }
    }
    .chart-card {
      background-color: $light-color;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      .card-title {
        font-size: $font-size-lg;
        text-align: center;
      }
    }

    .patient-card {
      background-color: $light-color;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      margin-top: $layout-spacing-lg;
      .card-title {
        font-size: $font-size-lg;
        text-align: center;
      }
    }

    .scroll-list-wrapper {
      flex-grow: 1;
      position: relative;
    }

    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      top: 0;
      width: 100%;
      height: 256px;
      @include scroll-bar();
    }
  }
</style>
